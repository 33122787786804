import React, {useState} from 'react'
import './FormPassword.css'
import PropTypes from 'prop-types'
import { FormField } from 'components/formFields/FormField/FormField'
import {Button} from "components/buttons/Button/Button";
import {ButtonTypes} from "utils/constants";
import {Icons, IconsNames} from "utils/icons";

const Types = {
    Input: 'input',
    Password: 'password'
}

export const FormPassword = ({
   label,
   fieldName,
   onChange,
   register,
   isRequired,
   error,
   dataTestId
}) => {
    const [type, setType] = useState({
        value: Types.Password,
        icon: Icons[IconsNames.EyeOff]
    });

    const handleSwitchVisibility = (e) => {
        e.preventDefault()
        if (type.value === Types.Password) setType({
            value: Types.Input,
            icon: Icons[IconsNames.Eye]
        });
        else setType({
            value: Types.Password,
            icon: Icons[IconsNames.EyeOff]
        });
    }

    return (
        <FormField
            label={label}
            isRequired={isRequired}
            dataTestId={dataTestId}
        >
            <div className='form-password-input-container'>
                <input
                    className={error?.length ? 'input-error' : 'common-input'}
                    type={type.value}
                    name={label}
                    required
                    {...register(fieldName, {
                        onChange: (e) => onChange && onChange(e.target.value),
                    })}
                    data-testid={dataTestId}
                />
                {Boolean(error) &&
                <div
                    key={error}
                    className='form-field-error-container'
                >
                    {error}
                </div>
                }
                <div className='form-password-eye'>
                    <Button
                        handleOnClick={handleSwitchVisibility}
                        type={ButtonTypes.Eye}
                        icon={type.icon}
                    />
                </div>
            </div>
        </FormField>
    )
}

FormPassword.propTypes = {
    label: PropTypes.string,
    fieldName: PropTypes.string,
    onChange: PropTypes.func,
    register: PropTypes.func,
    dataTestId: PropTypes.string,
}

FormPassword.defaultProps = {
    isRequired: true
}
