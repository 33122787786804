import React from 'react'
import LoginPage from "components/container/LoginPage/LoginPage";
import {SetupPasswordForm} from "components/forms/SetupPasswordForm/SetupPasswordForm";

const SetupPassword = () => {
    return (
        <LoginPage Form={SetupPasswordForm} />
    )
}
export default SetupPassword;
