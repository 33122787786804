import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './SetupPasswordForm.css';
import {
    SETUP_PASSWORD_HEADER,
    SETUP_PASSWORD_TEXT,
    NEW_PASSWORD,
    INVALID_PASSWORD
} from 'utils/messages';
import Loader from 'components/global/Loader/Loader';
import { ButtonTypes, ButtonSizes, ButtonLabels, FetchMethods } from 'utils/constants';
import { Button } from 'components/buttons/Button/Button';
import { FormPassword } from 'components/formFields/FormPassword/FormPassword';
import {
    BEAM_LOGIN,
    CHECK_THE_PASSWORD_CRITERIA,
    GET_USER_INFO,
    SET_NEW_PASSWORD
} from 'common/endpoints';
import fetchApi from 'common/fetchApi';
import { ErrorMessage } from 'components/global/ErrorMessage/ErrorMessage';
import { navigate } from 'gatsby';
import {useDispatch, useSelector} from 'react-redux'
import { auth } from 'store/selectors/auth'

export const SetupPasswordForm = () => {
    const { handleSubmit, register } = useForm();
    const [isLoading, setIsLoading] = useState();
    const [data, setData] = useState();
    const [passwordQuery, setPasswordQuery] = useState('');
    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();

    const birthDate = useSelector(auth.birthDate);

    useEffect(() => {
        const timeOutId = setTimeout(() => handlePasswordChange(passwordQuery), 500);
        return () => clearTimeout(timeOutId);
    }, [passwordQuery]);

    const fetchData = async (data) => {
        try {
            const response = await fetchApi(CHECK_THE_PASSWORD_CRITERIA, {
                method: FetchMethods.Post,
                data
            });

            setData(response.data.checks);
        } catch (error) {
            const [{ checks }] = error.response.data

            if (checks) {
                setData(checks)
            }
        }
    };

    useEffect(() => {
        if (!data) {
            fetchData({ password: '' });
        }
    }, []);

    const logIn = async () => {
        setIsLoading(true);
        try {
            const response = await fetchApi(SET_NEW_PASSWORD, {
                method: FetchMethods.Patch,
                data: {
                    date_of_birth: birthDate,
                    password: passwordQuery
                }
            });
            setShowError(false);

            const authResponse = await fetchApi(BEAM_LOGIN, {
                method: 'POST',
                data: { email: response.data.email_address, password: passwordQuery }
            });

            dispatch.auth.setLoggedIn({...authResponse.data, email: response.data.email_address});
            const userInfoResponse = await fetchApi(GET_USER_INFO);
            dispatch.auth.setUserInfo({...userInfoResponse.data});

            setShowError(false);
            setIsLoading(false);

            await navigate('/app/dashboard/');
        } catch {
            setShowError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordChange = async (e) => {
        if (e) {
            await fetchData({ password: e });
        }
    };

    const onSubmit = async () => {
        await logIn();
    }

    if (isLoading) {
        return (
            <div className='setup-password-loader-container'>
                <Loader/>
            </div>
        );
    }

    return (
        <form className='setup-password-form'>
            <div className='setup-password-header'>{SETUP_PASSWORD_HEADER}</div>
            {showError && <ErrorMessage message={INVALID_PASSWORD} />}
            <div className='setup-password-text'>{SETUP_PASSWORD_TEXT}</div>
            <div className='setup-password-setup-password'>
                <FormPassword
                    label={NEW_PASSWORD}
                    fieldName='new_password'
                    onChange={(e) => setPasswordQuery(e)}
                    register={register}
                    dataTestId='setup-password-input'
                />
            </div>
            <div className={!passwordQuery && 'setup-password-criteria-container'}>
                {data?.map((item) => {
                    return (
                        <div
                            className='setup-password-criteria'
                            key={item.name}
                        >
                            <div
                                className={`setup-password-criteria-status ${item.status}`}
                            />
                            <div className='setup-password-criteria-label'>
                                {item.name}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='setup-password-setup-button'>
                <Button
                    handleOnClick={handleSubmit(onSubmit)}
                    type={ButtonTypes.Primary}
                    size={ButtonSizes.Large}
                    label={ButtonLabels.Next}
                    dataTestId='setup-password-setup-button'
                />
            </div>
        </form>
    )
};